import './App.css';
import awsExports from "./aws-exports";
import {Amplify} from "aws-amplify";
import ClipBoard from "./ClipBoard";
import { v4 as uuidv4 } from 'uuid';

Amplify.configure(awsExports);

if (process.env.REACT_APP_ENV === 'production') {
  global.consoleDebug = console.debug;
  console.debug = function () {
    global.consoleDebug("Console debug is disabled in production");
  }
}

window[Symbol.for('clientBrowserId')] = uuidv4();
console.debug('Generated client ID', window[Symbol.for('clientBrowserId')]);

function App() {
  return (
    <ClipBoard />
  );
}

export default App;
